import React,{useState} from "react";
import { Container, Row, Col,Tab, Tabs } from "react-bootstrap";
import NewsCard from "../NewsCard/NewsCard";
import '../NewsTab/NewsTab.scss';
import CustomSliderNews from "../CustomSlider/CustomSliderNews";
import _ from "lodash"
import { Link, navigate } from "gatsby";
import ScrollAnimation from "react-animate-on-scroll";

const KEYS = {
    editor: "editor",
    news: "news",
    video: "video",
  }

const NewsSlider = (props) => {
    const [key, setKey] = useState(KEYS.editor)


    // const videoData=props.data.filter((data,index)=>
    // data.node.blog_categories?.some(item=>item.slug==="video"))

    // const newsData=props.data.filter((data,index)=>
    // data.node.blog_categories?.some(item=>item.slug==="blogs"))

    const SliderTab =({items})=>{
        if (_.isEmpty(items)) return null

        return(
            <CustomSliderNews className={"news-card-wrapper-main-space"} showMeThree>
            {items?.map(( node , index) => {

                return (
                    <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={index*100}>
                    <NewsCard data={node} />
                    </ScrollAnimation>
                )
            }
            )}
            </CustomSliderNews>
        )
    }

    const handleNews=(k)=>{
        setKey(k)
        if(k==="news"){
        navigate("/resources/latest-news-and-insights/")
        }
    }

    const sortedNews=props.data?.sort((a,b)=>{
        return new Date(b.node?.date) - new Date(a.node?.date)
    })

    return (
        <div className={`news-slider-section news-tab-wrapper ${props.className}`}>
            
            <Container>
                <Row className="news_slider_wrapper">
                    <Col>
                    <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
                    <h3>{props?.title}</h3>
                    </ScrollAnimation>
                    {props.pageName==="homepage"?
                    <Tabs
                    activeKey={key}
                    onSelect={k => handleNews(k)}
                    className="new-slider-tab-section"
                    >
                    {sortedNews?.length>0&&
                        <Tab eventKey={KEYS.editor} title="EDITOR'S PICK">
                        <SliderTab items={sortedNews?.slice(0,4)} />
                      </Tab>
                    }
                    {/* {newsData?.length>0&& */}
                        <Tab eventKey={KEYS.news} title="GO TO ALL NEWS">
                        <SliderTab items={sortedNews?.slice(0,4)} />
                      </Tab>
                    {/* } */}
                     {/* {videoData?.length>0&&
                        <Tab eventKey={KEYS.video} title="VIDEO">
                        <SliderTab items={videoData?.slice(0,4)} />
                      </Tab>
                    } */}
                 </Tabs>
                 :
                         <CustomSliderNews className={"news-card-wrapper-main-space"} showMeThree>
                            {sortedNews?.map(({ node }, index) => {
                                return (
                                    <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={index*100} offset={100}>
                                    <NewsCard data={node} />
                                    </ScrollAnimation>
                                )
                            }
                            )}
                            </CustomSliderNews> 
                        }
                    </Col>
                </Row>
            </Container>
            
        </div>
    )
}

export default NewsSlider