import loadable from "@loadable/component"
import { graphql } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import Accordion from "../components/AccordianComponent/Accordion"
import HomeBanner from "../components/Banner/HomeBanner"
import NewsSlider from "../components/NewsSlider/NewsSlider"
import Reviews from "../components/Reviews/Reviews"
import ValuationModule from "../components/ValuationModule/ValuationModule"
import Layout from "../components/layout"
import SEO from "../components/seo"
const FeatureComponent = loadable(() =>
  import("../components/FeatureComponent/FeatureComponent")
)
// import Banner from "../components/Banner/HomeBanner";
// const IntroModule = loadable(() => import("../components/IntroModule/IntroModule"));
// const InnerIntroModule = loadable(() => import("../components/InnerIntroModule/InnerIntroModule"));
// const TileBlock = loadable(() => import("../components/TileBlock/TileBlock"));
// const FeaturedProperties = loadable(() => import("../components/FeaturedProperties/FeaturedProperties"));
// const ReviewsSlider = loadable(() => import("../components/ReviewsSlider/ReviewsSlider"));
// const ValuationModule = loadable(() => import("../components/ValuationModule/ValuationModule"));
// const Enquire = loadable(() => import("../components/Enquire/Enquire"));

const HomePageTemplate = ({ data }, props) => {
  const PageData = data?.strapiPage
  const blogs = data?.allStrapiBlog?.edges
  const reviewData =
    data?.allStrapiSiteConfig?.edges?.length > 0 &&
    data?.allStrapiSiteConfig?.edges[0]

  let popUpData = ""

  if (PageData?.add_page_modules?.length > 0) {
    const results = PageData?.add_page_modules?.filter(
      item => item.__typename === "STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT"
    )
    if (results?.length > 0) {
      popUpData = results[0]
    }
  }

  // const [renderComponent, setRenderComponent] = useState(false)
  // useEffect(() => {
  //   window.addEventListener("mousemove", () => {
  //     if (renderComponent === false) {
  //       setRenderComponent(true)
  //     }
  //   })
  //   window.addEventListener("touchmove", () => {
  //     if (renderComponent === false) {
  //       setRenderComponent(true)
  //     }
  //   })
  //   window.addEventListener("keypress", () => {
  //     if (renderComponent === false) {
  //       setRenderComponent(true)
  //     }
  //   })
  //   window.addEventListener("scroll", () => {
  //     if (renderComponent === false) {
  //       setRenderComponent(true)
  //     }
  //   })
  // })

  var ldJson = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "haus & haus",
    alternateName: "haus & haus",
    url: "https://www.hausandhaus.com/",
    logo: "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i.prod/logo_white_schema_9fd27d4e15.png",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+971 4 302 5800",
      areaServed: "Dubai",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.7",
      reviewCount: "700+",
    },
    sameAs: [
      "https://www.facebook.com/hausandhaus",
      "https://www.instagram.com/hausandhaus/",
      "https://www.linkedin.com/company/haus-&-haus/",
    ],
  }

  return (
    <Layout
      popUpData={popUpData}
      popularSearch={PageData?.select_popular_search?.title}
    >
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(ldJson, null, 2)}
        </script>
      </Helmet>
      <HomeBanner
        title={PageData.title}
        {...PageData.banner}
        id={PageData.strapi_id}
        imagetransforms={
          PageData.imagetransforms?.banner_section_banner_image_Transforms
        }
      />

      {PageData.add_page_modules?.map((item, i) => {
        return (
          <div>
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_FEATURE_BLOCK"  &&  (
              <FeatureComponent item={item} />
            )}
            {item?.__typename === "STRAPI__COMPONENT_COMPONENTS_REVIEW" && (
              <Reviews {...item} reviewData={reviewData} />
            )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_NEWS_SELECTION" && (
              <NewsSlider {...item} data={item?.news} pageName="homepage" />
            )}

            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_VALUATION_IMAGE" && (
              <ValuationModule
                valuationData={item}
                imageData={item}
                PageData={PageData}
              />
            )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_ACCORDION" && (
              <Accordion
                add_toggle={item?.add}
                title="Why choose haus & haus"
              />
            )}
          </div>
        )
      })}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage

  let myimgtransforms = ""
  if (PageData?.imagetransforms?.banner_section_banner_image_Transforms) {
    myimgtransforms = JSON.parse(
      PageData?.imagetransforms?.banner_section_banner_image_Transforms
    )
  }

  let imageUrl=""
  if(myimgtransforms){
    const findImage = PageData?.banner?.image?.url?.substring(PageData?.banner?.image?.url?.indexOf("i.prod"));
    const ProcessedResult=findImage&&myimgtransforms[findImage]?myimgtransforms[findImage]["webp"]:""
    imageUrl=ProcessedResult?ProcessedResult["768x397"]:PageData?.banner?.image?.url
    }
  return (
    <SEO
      title={
        PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title
      }
      description={
        PageData?.seo?.metaDescription
          ? PageData?.seo?.metaDescription
          : PageData?.title
      }
      imageUrl={imageUrl}
    />
  )
}

export default HomePageTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...HomePageFragment

      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_FEATURE_BLOCK {
          __typename
          ...FeatureBlockFragment
        }
        ... on STRAPI__COMPONENT_COMPONENTS_REVIEW {
          __typename
          ...ReviewFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
          __typename
          id
          add {
            content {
              data {
                content
              }
            }
            title
          }
        }

        ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
          __typename
          ...GlobalPopUpFragment
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_NEWS_SELECTION {
          __typename
          id
          title
          news {
            tile_image {
              url
            }
            date
            imagetransforms {
              tile_image_Transforms
            }
            slug
            strapi_id
            read_time
            title
          }
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_VALUATION_IMAGE {
          __typename
          id
          title
          content {
            data {
              content
            }
          }
          cta_item {
            link {
              slug
              strapi_parent {
                slug
              }
            }
            title
            content {
              data {
                content
              }
            }
          }
          add_image {
            images {
              url
            }
          }
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES {
          __typename
          select_module
          title
          content {
            data {
              content
            }
          }
          add_cta_item {
            link {
              slug
            }
            title
            content {
              data {
                content
              }
            }
          }
        }
      }
    }

    allStrapiSiteConfig {
      edges {
        node {
          reviews_count {
            data {
              reviews_count
            }
          }
          star_rating
        }
      }
    }

    allStrapiBlog(
      filter: { publish: { eq: true } }
      sort: { fields: date, order: ASC }
    ) {
      edges {
        node {
          title
          slug
          video_url
          read_time
          date(formatString: "DD MMM yy")
          strapi_id
          tile_image {
            url
          }
          imagetransforms {
            tile_image_Transforms
          }
          blog_categories {
            blog_category
            slug
          }
        }
      }
    }
  }
`
