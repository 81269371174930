import { Link } from "gatsby"
import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Col, Container, Row } from "react-bootstrap"
import { PageLinks } from "../../common/site/page-static-links"
import reviewimg from "../../images/google-reviews.svg"
import SearchTeam from "../SearchTeam/SearchTeam"
import "./Reviews.scss"
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
const {
  CTALink,
} = require("@starberry/gatsby-theme-utils/Modules/CTALinkModule")
const Reviews = props => {
  const ratingValue = props?.reviewData?.node?.star_rating
  const ratingContent =
    props?.reviewData?.node?.reviews_count?.data?.reviews_count
  return (
    <section className={`reviews`}>
      <Container>
        <Row>
          <Col xl={12}>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={100}
              offset={100}
            >
              <div className="top-bk">
                {props.content && (
                  <ContentModule Content={props.content?.data?.content} />
                )}
                <div className="review-bk">
                  <div className="review-img">
                    <Link to={`/${PageLinks?.reviews}/`}>
                      <img
                        className="re-img"
                        src={reviewimg}
                        alt="reviews icon"
                      />
                    </Link>
                  </div>
                  <span className="vetical-review-line"></span>
                    <div className="feefo-block">
                    <span>
                      <i className="icon feefo-brand-icon"/>
                    </span>
                    <span className="rating-star feefo">
                      <i className="icon star-rating" />
                    </span>
                    </div>

                  {/* {props?.show_review && (
                    <>
                      <div className="review-txt">
                        <b>{ratingValue}/5</b>
                        <ContentModule Content={ratingContent} />
                      </div>
                    </>
                  )} */}
                </div>
              </div>
            </ScrollAnimation>
          </Col>
          <Col xl={12}>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={200}
              offset={100}
            >
              <div className="cta-block">
                {props?.add_cta?.map((item, i) => {
                  return (
                    <div
                      className={`${
                        item.title.includes("Communi") ||
                        item.title.includes("communi")
                          ? "cta-item item-" + i + " community"
                          : item.title.includes("Consultant") ||
                            item.title.includes("consultant")
                          ? "cta-item item-" + i + " customer"
                          : item.title ===
                            "Speak to a customer service specialist"
                          ? "cta-item item-" + i + " contact"
                          : "cta-item item-" + i
                      }`}
                    >
                      {item.title && <h3>{item.title}</h3>}
                      {item.content && (
                        <ContentModule Content={item.content?.data?.content} />
                      )}
                      {item?.cta_link ? (
                        <CTALink
                          class="button button-filled-green"
                          link={item.cta_link?.link}
                          title={item.cta_link?.title}
                        />
                      ) : null}
                      {item?.show_search ? <SearchTeam /> : null}
                    </div>
                  )
                })}
              </div>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Reviews
