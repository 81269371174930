import React from "react"
// import ScrollAnimation from "react-animate-on-scroll"
import { Col, Container, Row } from "react-bootstrap"
import SearchBox from "./../SearchBox/SearchBox"
import "./assets/styles/_index.scss"
// import HomeBannerImage from "./HomeBannerImage";

// import ContentModule from "../../modules/content-render";
// import CTALink from "../../modules/cta_link"
const {
  CTALink,
} = require("@starberry/gatsby-theme-utils/Modules/CTALinkModule")
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

// const ReviewBadge = loadable(() => import("../ReviewsSlider/ReviewBadge"));

const HomeBanner = props => {
  // const { site } = useStaticQuery(
  //     graphql`
  //     query {
  //       site {
  //         siteMetadata {
  //             elfSight {
  //               review
  //               review_badge
  //               review_carousel
  //               review_page
  //             }
  //         }
  //       }
  //     }
  //   `
  // )

  // const elfData = site.siteMetadata?.elfSight
  var imagename = "page.banner_section_banner_image.landing_banner_image"

  let processedImages = JSON.stringify({})
  if (props?.imagetransforms) {
    processedImages = props?.imagetransforms
  }
  // console.log(props)
  return (
    <section
      className={`banner ${props.tag === "landing" ? "inner-page" : ""} ${
        props?.show_search ? " home-search" : ""
      }`}
    >
      {/* <HomeBannerImage /> */}
      <ImageModule
        ImageSrc={props?.image}
        altText={`${
          props?.image?.alternativeText
            ? props?.image?.alternativeText
            : props?.banner_title
            ? props?.banner_title
            : props?.title
        } banner`}
        imagetransforms={processedImages}
        renderer="bgImg"
        imagename={imagename}
        strapi_id={props?.id}
        classNames="img-fluid banner-img"
      />

      <div className="overlay-bg"></div>
      <Container className="banner-search-container">
        <Row>
          <Col lg={12}>
            {/* <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={100}
              offset={100}
            > */}
            {props?.banner_title && <h1>{props.banner_title}</h1>}

            {props?.banner_content && (
              <ContentModule
                Content={props.banner_content?.data?.banner_content}
              />
            )}

            <ul className="list-inline">
              {(props?.cta_1_title && props?.cta_1_link) ||
                (props?.cta_1_custom_link && (
                  <li className="list-inline-item">
                    <CTALink
                      class="button button-filled-green"
                      link={
                        props.cta_1_link
                          ? props.cta_1_link
                          : { external_link: props?.cta_1_custom_link }
                      }
                      title={props?.cta_1_title}
                      //   external_link={props?.cta_1_custom_link}
                      target_window={props.cta_1_link?.target_window}
                    />
                  </li>
                ))}
              {/* {props.cta_2_title && props.cta_2_link && (
                <li className="list-inline-item">
                  <CTALink class="button button-secondary-outline-bg" link={props.cta_2_link} title={props.cta_2_title} target_window={props.cta_2_link.target_window} />
                </li>
              )} */}
            </ul>
            {/* </ScrollAnimation> */}
            {/* {elfData.review && <div className="google-reviews d-flex align-items-center">
                            <ReviewBadge code={elfData.review_badge} />
                        </div>} */}
          </Col>
        </Row>
      </Container>
      {props?.show_search && <SearchBox />}
    </section>
  )
}

export default HomeBanner
